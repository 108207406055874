@use '~scss/settings' as *;

.slider-buttons {
  @include flex(row, space-between);

  padding: 0 16px;

  .svg-button-wrapper {
    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
    p {
      margin: 0 16px;
      color: $color-primary-dark-blue;
    }

    svg {
      @include size(100%);
      width: 24px;
      height: 24px;
      color: $color-primary-dark-blue;

      path {
        stroke: currentColor;
      }
    }
  }
  button:disabled {
    svg {
      path {
        stroke: $color-text-light-grey;
      }
    }
    p {
      color: $color-text-light-grey;
    }
  }
}
