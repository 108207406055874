@use '~scss/settings' as *;

.members-actions {
  @include flex(row);
  @include gap(16px);

  .members-actions__delete-button {
    background-color: $color-orange-light;
  }

  .ncoded-select__selected {
    height: 51px;
    min-height: unset;
    min-width: 120px;
    > span {
      margin-right: 16px;
    }
  }
}
