@mixin input-placeholder {
  input {
    padding: 32px 16px 8px;

    &::placeholder {
      visibility: hidden;
    }

    &:focus {
      &::placeholder {
        visibility: visible;
      }
    }
  }
}
