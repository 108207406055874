@use '~scss/settings' as *;

.searchality-profile-contact {
  @include flex(column);

  gap: 20px;
  padding: 20px;
  border-radius: 20px;
  background: $color-white;
  border: 1px solid $color-primary-light-teal;
  color: $color-primary-dark-blue;

  .title {
    @extend %paragraph-subtitle;

    color: $color-primary-dark-blue;
  }

  > div {
    @include flex(row, flex-start, center);

    gap: 8px;

    > svg {
      @include size(24px);
    }

    a {
      @extend %paragraph;

      font-size: 14px;
      color: currentColor;
    }
  }
}
