@use '~scss/settings' as *;

.pop-notification {
  @include font(14px, 18px, 500);
  @include flex(row, flex-start, center);

  width: 320px;
  min-height: 64px;
  max-height: 90%;
  margin: 0 auto;
  position: fixed;
  z-index: 20;
  background-color: $color-white;
  border-radius: $border-radius;
  box-shadow: $card-shadow;
  padding: 12px 20px 12px 12px;
  border: solid 2px var(--color-primary);

  &--error {
    background-color: $color-error;
    color: $color-white;
    border: unset;
  }

  &--success {
    background-color: $color-primary-teal;
    color: $color-white;
    border: unset;
  }

  &--info {
    background-color: $color-secondary-light-grey-2;
    color: $color-white;
    border: unset;
  }

  &__close {
    @extend %button-reset;
    @include absolute(top 6px right 6px);
    @include size(16px);
    @include flex(row, center, center);

    color: currentColor;
    cursor: pointer;

    svg {
      @include size(14px);

      stroke-width: 1.5px;
      stroke: currentColor;
    }
  }

  --xAppear: 0;
  --yAppear: 0;
  --yDisappear: 0;
  --xConstant: 0;

  &.top {
    top: 20px;
    bottom: auto;
    --yDisappear: -150%;
  }

  &.bottom {
    bottom: 20px;
    top: auto;
    --yDisappear: 150%;
  }

  &.left {
    left: 20px;
    right: auto;
    --xAppear: -100%;
  }

  &.right {
    right: 20px;
    left: auto;
    --xAppear: 100%;
  }

  &:not(.left):not(.right) {
    left: 50%;
    --xConstant: -50%;
    --xAppear: -50%;

    &.top {
      --yAppear: -100%;
    }

    &.bottom {
      --yAppear: 100%;
    }
  }

  &-transition {
    &-appear {
      transform: translate3d(var(--xAppear), var(--yAppear), 0);
    }

    &-appear-active {
      transition: transform 250ms ease-in-out;
      transform: translate3d(var(--xConstant), 0, 0);
    }

    &-appear-done {
      transform: translate3d(var(--xConstant), 0, 0);
    }

    &-exit {
      transform: translate3d(var(--xConstant), 0, 0);
      opacity: 1;
    }

    &-exit-active {
      transition-property: transform, opacity;
      transition-duration: 250ms;
      transition-timing-function: ease-in-out;
      transform: translate3d(var(--xConstant), var(--yDisappear), 0);
      opacity: 0;
    }
  }
}
