@use '~scss/settings' as *;

.field-phone-number {
  @include flex(column, center, flex-start);

  .ncoded-select {
    height: unset;

    > .dropdown {
      &__content {
        width: inherit;
      }
    }
  }

  &__container {
    @include flex(row, center);
    @include gap(16px);

    width: 100%;

    .s-input-wrapper {
      flex: 1;
    }
  }
}
