@use '~scss/settings' as *;

button.ncoded-button.go-back-button {
  @include flex(row, center, center);

  gap: 8px;
  color: $color-primary-dark-blue;
  margin: 0;

  > svg {
    @include size(15px);

    transform: rotate(180deg);
    color: currentColor;

    > path {
      stroke: currentColor;
    }
  }
}
