@use '~scss/settings' as *;

.member-information {
  @include flex(row, space-between);
  margin-bottom: 48px;
  .photo-preview {
    margin-right: 40px;
  }
  &__left {
    flex: 1;
    @include flex(row);
  }
  &__data {
    h3 {
      @include font(32px, 38px, 800);

      color: $color-primary-dark-blue;
    }

    span {
      @include flex(row, flex-start, center);
      color: $color-secondary-light-grey-2;
      @extend %paragraph;
    }
    &__last-activity {
      color: $color-secondary-light-grey-2;
      @extend %input-descriptive;
    }
    strong {
      margin-right: 16px;
      position: relative;
      &::before {
        @include size(4px);

        position: absolute;
        content: '';
        top: 50%;
        left: calc(100% + 8px);
        transform: translate(-50%, -50%);
        background-color: currentColor;
        border-radius: 50%;
      }
    }
  }
  strong {
    color: $color-primary-dark-blue;
    @extend %paragraph;
  }
}
