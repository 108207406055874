@use '~scss/settings' as *;

.text-area-field--asterix {
  label {
    &::after {
      position: absolute;
      top: 0;
      right: -10px;
      content: '*';
      color: $color-required-asterisk;
    }
  }
}
