@use '~scss/settings' as *;

@media (min-width: 1100px) and (max-width: 1440px) {
  .job-preview-hero {
    &__circle {
      transform: translate(-30%, -50%);
    }
  }
}

@media (min-width: 768px) and (max-width: 1099px) {
  .job-preview-hero {
    &__circle {
      transform: translate(-20%, -50%);
    }
  }
}

@include phablet {
  .job-preview-hero {
    @include flex(column);

    gap: 18px;
    padding: 80px 20px 80px 20px;
    overflow: hidden;

    &__title {
      @include font-size(44px, 52px);
      width: unset;
    }

    &__circle {
      display: none;
    }
  }
}
