@use '~scss/settings' as *;

.group-settings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  &__account-type {
    @include flex(column);

    gap: 16px;

    h5 {
      @extend %paragraph-subtitle-small;

      min-height: 24px;
    }

    > form {
      flex: 1;

      button {
        margin-top: 12px;
      }
    }
  }
}
