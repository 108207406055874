@use '~scss/breakpoints' as *;
@use '~scss/color' as *;
@use '~scss/tools/tools' as *;
@use 'c-form-control' as *;
@use 'sass:math';

@mixin ncoded-input {
  .ncoded-input {
    @include base-input;

    position: relative;

    &,
    input {
      width: 100%;
    }

    &:focus-within {
      border-color: $color-primary-dark-blue;
    }

    &[class*='inner'] {
      --label-scale: #{math.div(14, 16)};

      @include input-placeholder;

      label {
        left: 16px;
      }
    }

    input {
      @include font(16px, 18.75px, 400);

      padding: 20px 16px;
      background-color: inherit;
      border: none;
      border-radius: inherit;
      color: $color-grey-2b;

      &:focus-visible {
        outline: none;
        outline-offset: unset;
        box-shadow: none;
      }

      &.password-input {
        padding-right: 42px;
      }
    }
  }
}
