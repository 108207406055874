@use '~scss/tools/tools' as *;
@use '~scss/color' as *;

%arrow {
  @include size(10px);

  content: '';
  position: absolute;
  background-color: inherit;
  border-color: $color-grey-d6;
}

%arrow-bottom {
  @extend %arrow;

  top: 0;
  left: 50%;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: translate(-50%, -50%) rotate(-45deg) translate(0, -1px);
}
