@use '~scss/settings' as *;

.searchality-candidate-card {
  &__wrapper {
    @include flex(row, flex-start, stretch);
    .ncoded-checkbox {
      margin-right: 16px;
    }
    .searchality-candidate-card {
      margin-bottom: 0;
    }
  }
}
