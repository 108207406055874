@mixin card {
  background-color: #fff; // $color-white;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(235, 235, 235, 0.08),
    0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px; //$border-radius-10;
  width: 100%;
  padding: 24px;
  color: #212121; //$color-text-dark-grey;
  border: 1px solid #bdd8d9; //$color-primary-light-teal;

  &--clickable:hover {
    background: #f7f7f7; //$color-grey-f7;
    border: 1px solid #007078; //$color-primary-teal;
    cursor: pointer;
  }
  &--active {
    background-color: rgba(#bdd8d9, 0.4);
    border-color: #00416a; //$color-primary-dark-blue;

    &:hover {
      background-color: rgba(#bdd8d9, 0.5);
    }
  }
}
