@use '~scss/general/general' as *;

.school-group-profile {
  @extend %page-content-container;

  &--preview {
    @media screen and (min-width: 1024px) {
      width: 100%;
    }
  }

  .back &--preview {
    width: 100%;
    max-width: 1400px;
  }
}
