@use '~scss/settings' as *;

.box-filters {
  @include flex(row, flex-start);

  gap: 24px;

  &--loading {
    opacity: 0.5;
  }

  .box {
    @include flex(column, flex-start, center);
    @include card;

    flex: 1;
    padding: 8px;
    opacity: 0.5;

    &--clickable:hover {
      h2,
      p {
        color: $color-primary-teal;
      }
    }

    h2 {
      @include font(40px, 47px, 900);

      color: $color-secondary-light-grey;
      margin-bottom: 16px;
    }

    p {
      @extend %paragraph-subtitle-small;

      text-align: center;
      color: $color-secondary-light-grey;
      padding: 0 8px;
    }

    &--active {
      background-color: $color-primary-dark-blue;

      h2,
      p {
        color: $color-primary-light-teal;
      }
    }

    &--active,
    &--clickable {
      cursor: pointer;
      opacity: 1;
      &:hover {
        h2,
        p {
          color: $color-primary-teal;
        }
      }
    }
  }
}
