@use '~scss/settings' as *;

@include phablet() {
  .school-profile-card {
    @include flex(column, flex-start, stretch);

    &__right {
      text-align: start;
    }
  }
}
