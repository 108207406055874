@use '~scss/settings' as *;

.searchality-profile-tabs {
  @include flex(row, space-between);

  gap: 32px;
  color: $color-primary-dark-blue;

  button.svg-button-wrapper {
    @extend %heading4;

    padding: 10px;
    color: $color-secondary-light-grey-2;

    &.active-tab {
      color: $color-primary-dark-blue;
      border-bottom: 3px solid currentColor;
      padding-bottom: 7px;
    }
  }
}
