@use '~scss/settings' as *;

.photo-preview {
  $s-width: 32px;
  $m-width: 42px;
  $l-width: 80px;
  $xl-width: 136px;
  $xxl-width: 216px;

  @include flex(row, center, center);

  border-radius: 50%;

  p {
    @extend %heading3;
    text-align: center;
  }

  img {
    width: 100%;
    border-radius: inherit;
    pointer-events: none;
  }

  &--s {
    @include size($s-width);
    min-width: $s-width;

    p {
      @extend %paragraph-subtitle-small;
    }
  }

  &--m {
    @include size($m-width);
    min-width: $m-width;

    p {
      @extend %paragraph-subtitle-small;
    }
  }

  &--l {
    @include size($l-width);
    min-width: $l-width;

    p {
      font-size: 16px;
    }
  }

  &--xl {
    @include size($xl-width);
    min-width: $xl-width;
  }

  &--xxl {
    @include size($xxl-width);
    min-width: $xxl-width;
  }
}
