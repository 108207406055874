@use '~scss/settings' as *;

.invite-admin-modal {
  &__fields {
    @include flex;

    width: 100%;
    gap: 16px;
    margin-bottom: 16px;

    > * {
      flex: 1;
    }
  }

  .form-field-label {
    @extend %paragraph-subtitle-small;

    margin: 24px 0 12px;
  }

  footer {
    .ncoded-button {
      padding: 0 32px;
    }
  }
}
