@use '~scss/settings' as *;

.enable2-fa-modal {
  .overlay__content {
    .ncoded-modal__content {
      main {
        @include flex(column, flex-start, center);

        gap: 16px;

        .radio-group {
          @include flex(row, flex-start, flex-start);
          @include gap(unset, column);

          width: 100%;
          gap: 32px;

          > * {
            flex: 1;
          }
        }
      }
    }
  }
}
