@use '~scss/color' as *;
@use '~scss/tools/tools' as *;

%button-primary {
  &:disabled {
    background-color: $color-text-light-grey;
    opacity: unset;

    &:active {
      transform: unset;
    }
  }
}

%button-link {
  background-color: transparent;
}

%button-secondary {
  color: $color-white;
  background-color: $color-primary-orange;
}

%ncoded-button {
  .ncoded-button {
    --nd-button-color: #{$color-primary-dark-blue};

    padding: 10px;
    min-width: unset;
    height: unset;
    min-height: 48px;

    > svg {
      margin: 0 6px;
    }

    &--solid {
      @extend %button-primary;
    }

    &--secondary {
      @extend %button-secondary;
    }

    &--link {
      padding: 10px 0px;

      &:active {
        color: $color-secondary-light-blue;
      }
    }
    &--solid,
    &--outline {
      min-width: unset;
    }
  }
}
