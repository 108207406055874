@use '~scss/settings' as *;

.create-association-information-fields {
  @include flex(column);

  align-self: center;
  width: 60%;
  gap: 16px;

  &__state-postcode {
    @include flex(row);

    gap: 12px;

    :first-child {
      flex: 1;
    }
  }
}
