@use '~scss/settings' as *;

.searchality-candidate-card {
  @include flex(row, flex-start, center);

  @include card;
  min-height: 170px;

  .photo-preview {
    margin-right: 24px;
  }
  &__info {
    @include flex(column);
    flex: 1;
    @include gap(12px, column);
    h1 {
      @extend %paragraph-subtitle;
    }
    &__applied {
      @include flex(row);
    }
    p {
      @extend %paragraph;
      color: $color-text-light-grey;
      &:last-of-type {
        color: $color-text-dark-grey;
      }
    }
    &__status {
      @extend %paragraph-descriptive;
      color: $color-primary-teal;
    }
  }
}
