@use '~scss/settings' as *;

.searchality-table {
  @include card;
  @include flex;

  padding: 0;
  align-self: stretch;
  overflow: hidden;
  overflow-x: auto;

  table {
    flex: 1;
  }

  &--active-column {
    color: $color-primary-orange;
  }

  &__sort-icon {
    margin-left: 4px;
  }

  &__header--sortable:hover,
  &__row--clickable:hover {
    cursor: pointer;
  }
}

.top-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  height: 13px;
  margin-bottom: 5px;
}

.scroll-content {
  height: 1px;
}
