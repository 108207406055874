@use '~scss/color' as *;
@use '~scss/breakpoints' as *;
@use '~scss/tools/tools' as *;
@use '~scss/variables' as *;
@use '~scss/general/general' as *;

%ncoded-checkbox {
  .ncoded-checkbox {
    @extend %paragraph-descriptive;

    input:checked + .ncoded-checkbox__box {
      background-color: $color-primary-light-teal;
    }

    &__box {
      @include size(18px);

      color: $color-primary-dark-blue;
      border: 2px solid currentColor;
      align-self: flex-start;
      border-radius: 2px;

      + * {
        margin-left: 12px;
      }

      @include phablet {
        @include size(15px);

        + * {
          margin-left: 8px;
        }
      }
    }

    &:not(.ncoded-checkbox--with-icon) {
      input:checked + .ncoded-checkbox__box:after {
        border-bottom-color: currentColor;
        border-right-color: currentColor;
      }
    }
  }
  .ncoded-styled-checkbox {
    .ncoded-checkbox {
      @extend %paragraph-descriptive;
      background-color: $color-white;

      padding: 16px 10px 16px 12px;
      border: 1px solid $color-primary-light-teal;
      border-radius: $border-radius-6;

      .ncoded-checkbox__box + * {
        margin-left: 0;
      }
      &__box {
        display: none;
      }

      &__label {
        @include gap(4px, column);
        @extend %input;
      }

      &--checked {
        background-color: $color-primary-dark-blue;
        color: $color-white;
        border-color: $color-primary-dark-blue;
      }
    }
  }
}
