@use '~scss/settings' as *;

.select-vacancies-modal {
  .ncoded-modal {
    &__content {
      min-width: 960px;
    }
  }

  .vacancies {
    width: 100%;
  }
}
