@use '~scss/settings' as *;

.template-illustration {
  @include flex(column, center, center);

  p {
    margin-top: 14px;
    max-width: 250px;
    text-align: center;
  }

  button {
    margin-top: 36px;
  }

  &__image-container {
    max-width: 200px;

    + h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 30px;
      text-align: center;
    }

    img,
    svg {
      width: 100%;
      height: auto;
    }
  }
}
