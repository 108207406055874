@use '~scss/settings' as *;

.job-preview-hero {
  @include flex(column);

  gap: 32px;
  width: 100%;
  background-color: $color-white;
  border-radius: 34px;
  position: relative;
  padding: 160px 0 160px 10%;
  overflow: hidden;
  margin-bottom: 100px;

  &__title {
    line-height: 75px;
    width: 50%;
    color: $color-text-dark-grey;
  }

  &__job-information {
    @include flex;

    gap: 22px;

    &__schools {
      @extend %paragraph-subtitle-small;

      color: $color-text-dark-grey;
    }

    &__employement-type {
      @extend %paragraph-subtitle-small;

      color: $color-primary-dark-blue;
      position: relative;

      &::before {
        @include size(6px);

        content: '';
        position: absolute;
        background-color: $color-grey-d9;
        border-radius: 50%;
        top: 50%;
        left: -11px;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__action-buttons {
    @include flex;

    gap: 12px;
  }

  &__circle {
    @include size(1200px);

    position: absolute;
    border-radius: 50%;
    background-color: rgba(189, 216, 217, 0.63);
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
  }
}
