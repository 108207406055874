@use '~scss/settings' as *;

.candidate-requirement {
  @include flex(row, space-between, center);

  &__left {
    @include flex(column, flex-start, flex-start);

    gap: 8px;

    &__title {
      @extend %heading4;

      color: $color-primary-dark-blue;
    }

    &__requirement {
      @extend %paragraph-subtitle-small;

      color: $color-secondary-light-grey;
    }

    &__candidate-have {
      @extend %input-descriptive;

      color: $color-primary-dark-blue;
    }

    p {
      word-break: break-word;
    }
  }

  svg {
    @include size(24px);

    min-width: 24px;

    path {
      fill: $color-primary-dark-blue;
    }
  }
}
