@use '~scss/settings' as *;

.profile-work-availability-section {
  svg {
    fill: $color-secondary-light-grey;
  }

  &__section {
    @include flex(column);

    gap: 4px;

    .title {
      color: $color-secondary-light-grey;
    }

    .value {
      @extend %paragraph-subtitle;
    }
  }
}
