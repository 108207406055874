@use '~scss/settings' as *;

@include phablet {
  .job-preview {
    padding: 32px 20px 48px;

    &__section-container {
      gap: 24px;
      padding: 0;
    }
  }
}
