@use '~scss/settings' as *;

.multiple-select-field {
  @include flex(column);
  @include gap(16px, column);

  &__tags {
    @include flex(row, flex-start, flex-start);

    flex-wrap: wrap;
    gap: 12px;
  }
  &__custom-option {
    .s-input-wrapper {
      flex: 1;
      margin-right: 32px;
    }

    @include flex(row, flex-start, center);
  }
}
