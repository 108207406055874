@use '~scss/color' as *;
@use '~scss/breakpoints' as *;
@use '~scss/tools/tools' as *;
@use 'c-form-control' as *;
@use 'sass:math';

@mixin ncoded-select {
  .ncoded-select {
    &__selected {
      @include base-input;

      min-height: 64px;

      @include phablet {
        padding: 8px 10px;
      }
    }

    &__value {
      height: 100%;
    }

    input {
      padding: 20px 16px;
    }

    &--inner {
      --label-scale: #{math.div(14, 16)};

      @include input-placeholder;

      .ncoded-select__content {
        @include clip-text;

        width: 100%;
        box-sizing: content-box;
        padding: 20px 20px 2px 6px;

        @include phablet {
          padding-bottom: 0;
        }
      }

      label {
        left: 16px;
      }
    }

    &--error {
      height: unset;

      .ncoded-select__selected {
        background-color: $color-white;
        height: unset;
      }

      label {
        color: inherit;
      }
    }

    &__option {
      &:hover {
        background-color: $color-grey-e6;
        color: $color-grey-2b;
      }

      &--selected {
        color: $color-grey-2b;
      }
    }
  }
}
