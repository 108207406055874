@use '~scss/settings' as *;

.searchality-pagination {
  @include flex(row, center, center);
  @include gap(23px);

  margin-top: 40px;

  .ncoded-button--solid {
    background-color: $color-grey-eb;
    color: $color-secondary-light-grey;

    width: 36px;
    height: 51px;
  }
  &__pages {
    @include flex;
    @include gap(16px);

    .page-button {
      @include font(20px, 27px, 700, 'Open Sans');
      width: 48px;

      padding: 12px 16px;

      &--active {
        background-color: $color-primary-light-teal;
      }
    }
    span {
      @include font(30px, 30px);
      align-self: flex-end;
      margin-bottom: 5px;
      color: $color-primary-teal;
    }
  }

  button[class*='arrow'] {
    padding: 0 2px;

    &:disabled {
      background-color: $color-grey-eb;
      opacity: 0.7;

      svg {
        path {
          stroke: $color-text-light-grey;
        }
      }
    }
  }

  .arrow-right {
    svg {
      transform: rotate(180deg);
    }
  }
}
