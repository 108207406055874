@use '~scss/settings' as *;

.association-schools {
  &__input-sort {
    @include flex(row, flex-start);

    width: 100%;

    gap: 24px;

    .s-input-wrapper {
      flex: 1;
    }
  }

  .searchality-pagination-info {
    margin-top: 16px;
  }
}
