@use '~scss/settings' as *;

.password-field {
  .ncoded-input {
    padding-right: 0;
  }

  input + button {
    @extend %button-reset;

    right: 8px;
    position: absolute;
    border-radius: 50%;
    padding: 8px;
    display: inline-flex;
    aspect-ratio: 1;
    align-items: center;

    &:active {
      transform: scale(0.95);
    }
  }

  input[type='text'] + button .slash {
    display: none;
  }

  input {
    padding-right: 48px !important;
  }
}
