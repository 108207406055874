@use '~scss/settings' as *;

.hris-settings {
  &__title {
    @include flex(row, space-between, center);

    > div {
      @include flex(row);

      gap: 24px;
    }
  }

  &__form {
    &__fields {
      @include flex(column);

      gap: 40px;
      margin: 100px auto 0;
      width: 900px;

      &__column {
        @include flex(row);

        * {
          @extend %paragraph-subtitle;

          flex: 1;
        }
      }
    }
  }
}
