@use '~scss/color' as *;

%ncoded-range {
  .ncoded-range {
    &__track {
      background-color: $color-grey-af;
    }
    &__track-range {
      background-color: $color-primary-light-teal;
    }

    &__min-thumb,
    &__max-thumb {
      > button {
        background-color: $color-primary-dark-blue;
        color: $color-white;
      }

      > div {
        display: none;
      }
    }
  }
}
