@use '~scss/settings' as *;

.searchality-profile-languages {
  &__section {
    @include flex(column);

    gap: 8px;

    .title {
      @extend %heading4;

      color: $color-secondary-light-grey;
    }

    .subtitle {
      @extend %input-descriptive;

      color: $color-primary-dark-blue;
    }

    .description {
      @extend %input-descriptive;

      color: $color-secondary-light-grey-2;
    }
  }
}
