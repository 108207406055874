.member-role-select {
  width: 200px;

  .ncoded-select__selected {
    justify-content: flex-end;

    > span {
      flex: 1;
    }
  }
}

.portal {
  .ncoded-select__options {
    width: 200px;
  }
}
