@use '~scss/settings' as *;

.member-vacancy-card {
  .searchality-vacancy-card {
    min-height: unset;
    &__right {
      @include flex(column, center, center);
      color: $color-text-light-grey;
    }
  }
}
