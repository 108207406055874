@use '~scss/settings' as *;

.searchality-chip {
  @include flex(row, center, center);
  @include gap(12px, row);

  padding: 12px 24px;
  color: $color-primary-light-teal;
  background-color: $color-white;
  border: 1px solid currentColor;
  border-radius: 87px;
  align-self: baseline;
  > span {
    @include flex(row, flex-start, center);

    @extend %opensans;
    @extend %heading4;

    gap: 4px;
    color: $color-primary-dark-blue;
  }

  > svg {
    &:hover {
      cursor: pointer;
    }
  }

  &.small {
    padding: 10px;
  }
}
