@use '~scss/settings' as *;

@include phablet-portrait {
  .admin-login {
    @include flex(column, center, center);

    gap: 32px;

    &__titles {
      flex: 0;

      h1 {
        @include font-size(32px, 38px);

        text-align: center;

        + p {
          margin-top: 16px;
        }
      }

      img {
        display: none;
      }
    }

    .login-form {
      flex: 0;
    }
  }
}
