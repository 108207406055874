@use '~scss/settings' as *;

.searchality-basic-tag {
  @extend %paragraph-subtitle-small;

  font-family: 'Open sans', sans-serif;
  border-radius: $border-radius-5;
  padding: 10px;
  display: block;
  color: $color-white;

  &--blue {
    background-color: $color-primary-dark-blue;
  }

  &--orange {
    background-color: $color-orange-light;
  }
  &--lightGrey {
    background-color: $color-secondary-light-grey-2;
    color: $color-white;
  }

  &--outline {
    border: 1px solid $color-primary-light-teal;
    color: $color-primary-teal;
    background-color: transparent;
  }
}
