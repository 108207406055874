@use '~scss/settings' as *;

.member-info {
  @include flex(row, flex-start, center);
  .photo-preview {
    margin-right: 20px;
    min-width: 42px;
  }

  &__text {
    @include flex(column, center);

    &__name {
      color: $color-primary-dark-blue;
    }

    &__email {
      @extend %input-descriptive;

      color: $color-secondary-light-grey;
    }
    &__expire {
      @extend %input-descriptive;
      margin-left: 12px;
      color: $color-secondary-light-grey-2;
      position: relative;

      &::before {
        @include size(4px);
        background-color: $color-secondary-light-grey;
        content: '';
        position: absolute;
        left: -6px;
        top: 6px;
        transform: translate(-50%, 50%);
        border-radius: 50%;
      }
    }
  }
}
