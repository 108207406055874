@use '~scss/settings' as *;

.vacancies-filters {
  @include flex(row, space-between, flex-start);
  width: 100%;
  &__wrapper {
    @include flex(column, center, flex-start);
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  &__left {
    @include flex(row, flex-start);

    flex: 1;
    flex-wrap: wrap;

    gap: 16px;
    .ncoded-select {
      width: 200px;
    }
    .vacancies-filters__wide-select {
      width: 250px;
    }
  }
  &__right {
    .ncoded-select {
      width: 200px;
    }
  }
  .ncoded-select__selected {
    padding: 0 10px;
    min-height: 48px;
  }
}
