@use '~scss/settings' as *;

.association-school-card {
  @include card;
  @include flex(column, space-between, stretch);

  p {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
  }

  gap: 44px;
  cursor: pointer;

  &__top {
    @include flex(column);

    gap: 4px;

    &__state {
      @extend %paragraph-subtitle-small;

      color: $color-secondary-light-grey;
    }

    &__name {
      @extend %paragraph-subtitle;

      color: $color-text-dark-grey;
    }

    &__type {
      @extend %paragraph-descriptive;

      color: $color-secondary-light-grey;
    }
  }
  &__bottom {
    @include flex(row, space-between, flex-end);

    > * {
      height: 51px;
    }

    &__contact-information {
      &__name {
        @extend %paragraph-subtitle-small;

        color: $color-secondary-light-grey;
      }

      &__email {
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;
        margin-top: 8px;
      }
    }

    &__total-vacancies {
      display: none;
      &__number {
        @extend %paragraph-subtitle-small;

        color: $color-secondary-light-grey;
      }

      &__total {
        @extend %paragraph-descriptive;

        color: $color-secondary-light-grey;
        margin-top: 8px;
        display: inline-block;
      }
    }

    &__view-on-website {
      @include flex(row, center, center);

      padding: 12px;
      border: 1px solid #bdd8d9;
      border-radius: $border-radius;
      color: $color-primary-dark-blue;
    }

    &__actions {
      @include flex(column, center, center);
      display: none;

      gap: 8px;

      &__label {
        color: $color-secondary-light-grey;
      }

      &__buttons {
        @include flex(row, center, center);

        gap: 10px;

        button.svg-button-wrapper {
          min-height: fit-content;
          padding: 0;

          svg {
            color: $color-secondary-light-grey;
          }
        }
      }
    }
  }

  &:hover {
    background: #f2f2f2;
    /* Button drop shadow */
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.05),
      0px 4px 14px 0px rgba(231, 231, 231, 0.05),
      0px 4px 4px 0px rgba(235, 235, 235, 0.08),
      0px 8px 10px 0px rgba(0, 0, 0, 0.04);

    .association-school-card {
      &__bottom {
        &__contact-information {
          display: none;
        }

        &__total-vacancies {
          display: block;
        }

        &__view-on-website {
          display: none;
        }

        &__actions {
          display: flex;
        }
      }
    }
  }
}
