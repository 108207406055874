@use '~scss/settings' as *;

.association-information-form {
  button.clear-form-button {
    @include flex(row, flex-end, flex-end);

    @extend %paragraph;

    background-color: transparent;
    color: $color-secondary-light-grey-2;
    padding: 0;
    min-width: 120px;
  }

  .create-association-section {
    margin-top: 30px;
  }

  > button {
    margin-top: 30px;
  }
}
