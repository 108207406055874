@use '~scss/settings' as *;

.main-layout {
  @include flex(column);

  flex: 1;
  background-color: $color-gray-f6;

  > main {
    @include flex(row);

    flex: 1;
    padding: 48px;

    article {
      flex: 1;
      display: flex;
      overflow: hidden;
      justify-content: center;
    }
  }

  &.auth {
    background: $color-primary-dark-blue url(/assets/images/auth.svg) no-repeat;
    background-position: 0 100%;
    background-size: 100%;
  }
}
