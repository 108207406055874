@use '~scss/tools/tools' as *;
@use '~scss/_breakpoints' as *;

%page-content-container {
  @include size(100%);
  @include flex(column);

  margin: 0 auto;

  .searchality-pagination-info {
    margin-bottom: 32px;
  }

  @include tablet() {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    width: 900px;
  }

  @media screen and (min-width: 1440px) {
    width: 940px;
  }

  @media screen and (min-width: 1728px) {
    width: 1120px;
  }

  @media screen and (min-width: 1920px) {
    width: 60%;
  }

  .go-back-button {
    align-self: flex-start;
  }
}
