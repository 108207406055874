@use '~scss/settings' as *;

.candidate-qualification {
  @include flex(column, space-between, flex-end);

  gap: 12px;

  &__title {
    @extend %input-descriptive;

    color: $color-text-light-grey;
  }

  &__main-content {
    @extend %heading4;

    &--blue {
      color: $color-secondary-light-blue;
    }

    &--green {
      color: $color-primary-teal;
    }

    &--red {
      color: $color-error;
    }
  }

  &__message {
    @extend %paragraph-descriptive;

    color: $color-text-dark-grey;
  }
}
