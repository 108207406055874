@use '~scss/settings' as *;

.login-form {
  @include card;

  h1 {
    @extend %paragraph-subtitle;

    color: $color-primary-orange;
  }

  &__forgot-password {
    @extend %paragraph-descriptive;

    align-self: baseline;
    background-color: transparent;
    padding: 0;
    color: $color-anchor;
  }

  button[class*='login__button'] {
    width: unset;
  }

  &__form {
    @include flex(column, flex-start, stretch);

    gap: 16px;
    margin-top: 26px;

    .ncoded-button--primary {
      height: 50px;
    }
  }

  &__title {
    @include flex(row, flex-start, center);

    gap: 12px;
  }

  .auth-form {
    padding: 42px 32px;
    gap: 10px;
  }
}
