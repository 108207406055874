@use '~scss/settings' as *;

.searchality-profile-main {
  @include flex(column);

  position: relative;
  background-color: $color-white;
  border: 1px solid $color-primary-light-teal;
  border-radius: 20px;
  padding: 24px 0;

  hr {
    margin: 0;
    border: 0.5px solid $color-primary-light-teal;
  }

  &__avatar {
    position: absolute;
    left: 32px;
    top: -108px;
    border: 3px solid $color-white;

    p {
      @extend %heading1;
    }
  }

  &__personal-info {
    @include flex(column);

    margin-top: 100px;
    gap: 12px;
    color: $color-primary-dark-blue;

    padding: 0 32px;

    &__name {
      @extend %heading2;
    }

    &__info {
      @include flex(row);

      gap: 8px;
      color: $color-secondary-light-grey-2;

      &__main {
        color: $color-primary-dark-blue;
      }

      &__bullet {
        @include size(4px);

        background-color: $color-primary-dark-blue;
        align-self: center;
        border-radius: 50%;
      }
    }
  }

  .searchality-profile-tabs {
    padding: 0 32px;
  }
}
