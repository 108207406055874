@use '~scss/settings' as *;

@include phablet {
  .job-preview-section {
    @include flex(column);

    gap: 32px;

    &__left {
      &__section-number {
        @include font(44px, 52px, 700);
      }

      &__title {
        @include font(32px, 38px, 900);
      }
    }
  }
}
