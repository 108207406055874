@use '~scss/settings' as *;

.vacancy-candidates {
  &__info-section {
    @include flex(row, space-between, center);
    margin: 28px 0 18px;
    h6 {
      @extend %paragraph-subtitle-small;

      margin-bottom: 5px;
    }

    &__select {
      min-width: 200px;
    }

    p.searchality-pagination-info {
      margin-bottom: unset;
    }
  }
}
