@use '~scss/settings' as *;

@include phablet {
  .searchality-header {
    @include flex(row, center, center);

    &.indent {
      padding: 12px;
    }
  }
}
