@use '~scss/settings' as *;

.school-profile-card {
  @include flex(row, space-between, center);
  @include card;

  gap: 24px;

  &__left {
    @include flex(column, space-between, flex-start);

    gap: 32px;
    flex: 1;
  }

  &__right {
    @extend %paragraph;

    text-align: end;
    white-space: pre-wrap;
    color: $color-text-light-grey;
  }

  p {
    &.title {
      @extend %paragraph;

      color: $color-text-light-grey;
    }

    &.price {
      @extend %paragraph-subtitle;

      color: $color-black;
    }

    &.date {
      @extend %input;

      color: $color-primary-dark-blue;
    }
  }
}
