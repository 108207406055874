@use '~scss/settings' as *;

:root .s-select {
  @include form-control(false);

  &--error {
    .ncoded-select__selected {
      background-color: $color-grey-fc;
    }

    .ncoded-input {
      border-color: $color-error;

      &,
      input {
        color: $color-error;
      }
    }
  }

  &--asterisk {
    label {
      &::after {
        position: absolute;
        top: 0;
        right: -10px;
        content: '*';
        color: $color-required-asterisk;
      }
    }
  }
}
