@use '~scss/settings' as *;

.associations {
  @extend %page-content-container;

  .searchality-pagination-info {
    margin-top: 16px;
  }

  .searchality-table {
    table {
      thead {
        > tr {
          > :last-child {
            max-width: 140px;
            min-width: 140px;
          }
        }
      }
      tbody {
        tr {
          > :last-child {
            max-width: 140px;
            min-width: 140px;
          }
        }
      }
    }
  }

  &__table {
    &__account-status {
      @extend %paragraph-subtitle-small;

      color: $color-primary-orange;

      &--active {
        color: $color-primary-teal;
      }
    }
  }
}
