@use '~scss/settings' as *;

.searchality-profile-work-profile {
  .iframe-video-wrapper {
    iframe.loading {
      pointer-events: none;
      background-color: $color-secondary-light-grey;
    }
  }

  &__availability {
    svg {
      fill: $color-secondary-light-grey;
    }

    &__section {
      @include flex(column);

      gap: 4px;

      .title {
        color: $color-secondary-light-grey;
      }

      .value {
        @extend %paragraph-subtitle;
      }
    }
  }
}
