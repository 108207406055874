@use '~scss/settings' as *;

.create-association-section {
  @include flex(column, flex-start, stretch);

  gap: 12px;
  width: 940px;

  &__title {
    @extend %heading4;
  }

  &__subtitle {
    @extend %paragraph-descriptive;
  }

  > p:last-of-type {
    border-bottom: 1px solid $color-primary-light-teal;
    padding-bottom: 12px;
    margin-bottom: 18px;
  }
}
