@use '~scss/settings' as *;

.pending-invite {
  @include flex(row, flex-start, center);

  gap: 12px;
  width: fit-content;
  padding: 8px;
  border-radius: $border-radius-5;
  background: rgba(189, 216, 217, 0.7);

  > p {
    @include font(16px, 26px, 400);

    color: $color-primary-teal;
  }

  &__dots {
    @include flex(column);

    gap: 2px;
    width: 8px;
    cursor: pointer;

    span {
      @include size(3px);

      border-radius: 50%;
      background-color: $color-primary-teal;
    }
  }

  &__actions {
    @include flex(column);

    width: 150px;

    &__action {
      @include flex(row, flex-start, center);

      gap: 8px;
      padding: 5px 6px 5px;
      color: $color-secondary-light-grey;
      cursor: pointer;

      &:hover {
        background-color: $color-gray-ea;
      }

      &:first-of-type {
        padding-top: 8px;
      }

      &:last-of-type {
        padding-bottom: 8px;
      }

      p {
        @extend %input-descriptive;
      }
    }
  }
}
