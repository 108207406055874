@use '~scss/settings' as *;

.members-filters {
  @include flex(column, flex-start, flex-start);

  &__select-wrapper {
    @include flex;

    gap: 12px;
    min-width: 50%;

    .ncoded-select {
      width: 225px;

      &__selected {
        padding: 0 10px;
        min-height: 48px;
      }
    }

    button[class*='members-filters__reset'] {
      align-self: flex-end;
      align-items: flex-end;
      padding-bottom: 4px;
    }
  }
}
