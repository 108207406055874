@use '~scss/settings' as *;

.candidates-filters {
  @include flex(row, space-between, flex-start);
  width: 100%;

  &__wrapper {
    @include flex(column, center, flex-start);
  }

  &__left {
    flex: 1;
    .ncoded-select {
      width: 200px;
    }
    .candidates-filters__wide-select {
      width: 250px;
    }

    @include gap(16px);

    @include flex(row, flex-start);
  }

  &__right {
    .ncoded-select {
      width: 200px;
    }
  }
  .ncoded-select__selected {
    padding: 0 10px;
    min-height: 48px;
  }
}
