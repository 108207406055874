@use '~scss/settings' as *;

.empty-screen {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 12px;
  }

  p {
    margin-top: 10px;
  }

  button {
    margin-top: 28px;
  }

  .template-illustration__image-container {
    max-width: 320px;
  }
}
