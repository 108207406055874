@use '~scss/settings' as *;

.searchality-country-picker {
  cursor: pointer;
  flex: 1;

  .dropdown {
    position: relative;

    &__content {
      z-index: 20;
      max-height: 300px;
      overflow: hidden;
      width: 100%;
    }

    &__trigger {
      width: 100%;

      &__label {
        @include flex(row, flex-start, center);

        background-color: $color-grey-fc;
        border: 1px solid $color-grey-d6;
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: $border-radius-5;
        padding-left: 4px;

        > img {
          width: 36px !important;
          height: 30px !important;
          border: 2px solid $color-white;
          border-radius: $border-radius;
          margin-right: 5px;
        }

        > div {
          position: absolute;
          inset: 0;
          z-index: 10;
        }

        &__input {
          border: none;

          &--hide {
            display: none;
          }
        }
      }
    }
  }

  .overlay {
    z-index: 10 !important;
  }
}

.portal {
  .dropdown__content {
    z-index: 15;
    max-height: 300px;
    overflow: auto;
  }
}

.portal + .overlay {
  z-index: 12;
}

.country-list {
  background-color: $color-white;
  color: $color-black;
  width: 100%;
  padding: 4px;
  max-height: 300px;
  overflow: auto;

  .searchality-country {
    @include flex(row, flex-start, center);

    cursor: pointer;
    margin-bottom: 0;
    padding: 10px 0;

    > img {
      width: 32px !important;
      height: 25px !important;
      border: 2px solid $color-primary-light-teal;
      border-radius: $border-radius;
      margin-right: 5px;
    }

    &--selected {
      font-weight: 700;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
