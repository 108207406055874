@use '~scss/settings' as *;

.reject-condidate-modal {
  main {
    @include flex(column);

    gap: 24px;

    form {
      @include flex(column);

      width: 100%;
      gap: 12px;

      .ncoded-textarea-container {
        &__wrapper {
          min-height: 360px;
        }
      }

      .actions {
        @include flex(row, flex-end);

        gap: 8px;
      }
    }
  }
}
