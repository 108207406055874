@use '~scss/settings' as *;

.vacancies-fill-factory {
  svg {
    width: 24px;
    height: 24px;
  }

  &__description {
    @extend %input-descriptive;
    margin-bottom: 32px;
    margin-top: 12px;
    color: $color-secondary-light-grey;
  }
  ul {
    div {
      margin-top: 20px;
    }

    div:first-of-type {
      margin-top: 0;
    }
  }
}
