@use '~scss/settings' as *;

.create-association-type-field {
  align-self: center;
  width: 60%;

  &__states {
    @include flex(column);

    margin-top: 40px;
    gap: 16px;

    > p {
      @extend %paragraph-subtitle-small;
    }
  }
}
