@use '~scss/settings' as *;

.members-profile {
  width: 900px;
  padding: 48px 0;
  margin: 0 auto;
  button.ncoded-button.go-back-button {
    margin-bottom: 56px;
  }
}
