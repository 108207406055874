@use '~scss/settings' as *;

.forgot-password {
  &__dont-have-account {
    @include flex;

    gap: 8px;
  }

  button[class*='forgot-password__submit'] {
    padding: 16px;
  }
}
