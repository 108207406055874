@use '~scss/settings' as *;

.change-password {
  width: 450px;

  form {
    @include flex(column);

    gap: 16px;
  }
}
