@use '~scss/settings' as *;

.authenticate {
  @include card;

  max-width: 500px;

  form {
    @include flex(column, flex-start, stretch);

    gap: 32px;

    button[type='submit'] {
      min-width: 120px;
      align-self: flex-start;
    }
  }

  &__titles {
    h1 {
      @extend %heading2;

      color: $color-primary-orange;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 24px;
      color: $color-secondary-light-grey;
      position: relative;
    }
  }
}
