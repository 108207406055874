@use '~scss/settings' as *;

@use '~scss/settings' as *;

.admin-login {
  @include size(100%);
  @include flex(row, center, center);

  gap: 128px;

  &__titles {
    h1 {
      @extend %heading2;

      color: $color-primary-orange;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 24px;
      color: $color-primary-orange;
      position: relative;
    }
  }

  .login-form {
    max-width: 500px;
  }
}
