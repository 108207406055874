@use '~scss/settings' as *;

.comment {
  @include flex(row);

  gap: 20px;

  &--loading {
    opacity: 0.5;
  }

  &__left {
    min-width: 42px;

    p {
      @extend %paragraph-subtitle-small;
    }
  }

  &__right {
    @include flex(column);

    gap: 8px;

    p {
      word-break: break-all;
      white-space: normal;
    }

    &__name {
      @extend %heading4;

      color: $color-secondary-light-grey;
    }

    &__info {
      @extend %input-descriptive;

      color: $color-primary-dark-blue;
      max-width: 240px;
    }
  }
}
