@use '~scss/settings' as *;

.profile-pdf-section {
  &__file-container {
    @include flex(column, center, center);

    width: 100%;
    max-width: 400px;
    background-color: $color-grey-fc;
    border-radius: $border-radius-20;
    padding: 24px;
    gap: 12px;

    &__iframe {
      max-width: 200px;
      border: 1px solid $color-grey-e6;
      border-radius: 5px;
    }

    &__text {
      @include flex(row, center, center);

      gap: 12px;

      span {
        @include flex(column);

        gap: 8px;

        p:first-of-type {
          @extend %input-descriptive;

          text-decoration: underline;
          color: $color-primary-dark-blue;
          cursor: pointer;
        }

        p:last-of-type {
          @extend %paragraph-descriptive;
        }
      }
    }
  }
}
