@use '~scss/settings' as *;

.members-page {
  @extend %page-content-container;

  width: 100%;

  &__pagination-info {
    margin-top: 24px;
  }

  &__table,
  .empty-state-members {
    margin-top: 44px;
  }

  .empty-state-members {
    @include flex(column, center, center);

    gap: 24px;
    margin-top: 44px;

    p {
      text-align: center;

      &.title {
        @extend %heading4;

        color: $color-secondary-light-grey;
      }

      &.description {
        color: $color-primary-dark-blue;
      }
    }
  }
}
