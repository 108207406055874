@use '~scss/settings' as *;

.vacancy-fill-modal {
  main {
    > p {
      margin-bottom: 32px;
      @extend %paragraph-descriptive;
    }
  }
}
