$global-font-size: 16;
$overlay-window-z: 5;
$menu-z-index: 10;
$modal-z-index: 20;
$border-radius: 8px;
$border-radius-5: 5px;
$border-radius-6: 6px;
$border-radius-10: 10px;
$border-radius-20: 20px;
$padding-169: 56.25%;
$card-shadow: 0px 0px 44px -10px rgba(0, 0, 0, 0.76);
