@use '~scss/settings' as *;

@include phablet() {
  .invoice {
    &__table {
      overflow: auto;

      > * {
        width: 650px;
      }
    }
  }
}
