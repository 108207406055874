@use '~scss/settings' as *;

.searchality-review-card-item {
  @include flex(column);

  gap: 8px;

  label {
    @extend %input-descriptive;

    color: $color-grey-b5;
  }

  p {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__description {
    @extend %paragraph-descriptive;

    color: $color-secondary-light-grey;
  }

  .orange {
    color: $color-primary-orange;
  }

  .blue {
    color: $color-primary-teal;
  }
}
