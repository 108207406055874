@use '~scss/settings' as *;

.association-account {
  @include flex(row, flex-start, stretch);

  flex-wrap: wrap;
  gap: 40px;

  > * {
    flex-basis: calc(50% - 40px);
  }

  &__account-type {
    @include flex(column);

    gap: 16px;

    h5 {
      @extend %paragraph-subtitle-small;

      min-height: 24px;
    }

    > form {
      flex: 1;

      button {
        margin-top: 12px;
      }
    }
  }

  .searchality-review-card {
    max-width: calc(50% - 40px);
  }

  &__add-owner {
    @include flex(column, center, center);

    gap: 20px;
  }

  .ncoded-modal__content {
    width: 940px;

    form {
      @include flex(column, flex-start, stretch);

      gap: 24px;
      width: 100%;

      > div {
        @include flex(row, flex-start, stretch);

        gap: 12px;

        > * {
          flex: 1;
        }
      }
    }
  }
}
