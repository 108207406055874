@use '~scss/settings' as *;

.invoice {
  @include flex(column);

  gap: 16px;

  &__table {
    @include card;
    @include flex(column);

    gap: 32px;

    &__header,
    &__row__main {
      @include flex(row, flex-start, center);

      > * {
        flex: 1;
      }
    }

    &__header {
      p {
        @extend %paragraph-subtitle-small;

        color: $color-secondary-light-grey;
      }
    }

    &__content {
      @include flex(column);

      gap: 32px;
    }

    &__row {
      @include flex(column);

      gap: 16px;

      p.status {
        text-transform: capitalize;

        &--paid {
          color: $color-primary-teal;
        }

        &--open {
          color: $color-primary-orange;
        }
      }

      &__main,
      &__items {
        cursor: pointer;
      }

      &__items {
        @include flex(column);

        padding: 12px 0;
        gap: 8px;
        border-bottom: 1px solid $color-primary-teal;
      }
    }
  }
}
