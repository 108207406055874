@use '~scss/settings' as *;

.job-preview {
  @include flex(column);

  padding: 48px 48px 160px;
  background-color: $color-grey-f7;

  .role-description-container {
    overflow-wrap: break-word;

    span,
    p {
      background-color: transparent !important;
    }
  }

  &__section-container {
    @include flex(column);

    gap: 100px;
    padding: 0 80px;

    &__section-subtitle {
      @extend %paragraph-subtitle;

      margin: 12px 0;
    }
  }

  &__button-container {
    @include flex;

    gap: 12px;
  }
}
