@use '~scss/settings' as *;

.searchality-tabs-navigation {
  @include flex;
  @include gap(16px);

  margin-bottom: 48px;

  &__link {
    @extend %paragraph;

    color: $color-secondary-light-grey-2;
    border-radius: $border-radius-10;
    padding: 10px;

    &--active {
      background-color: $color-white;
      color: $color-primary-dark-blue;
    }

    &--disabled {
      pointer-events: none;
    }
  }
}
