@use '~scss/settings' as *;

.users-feedback {
  width: 900px;

  &__count {
    @include flex(column);

    gap: 12px;
    border: 1px solid $color-primary-light-teal;
    border-radius: $border-radius-10;
    padding: 20px;
    background-color: $color-white;
    margin-top: 12px;

    p {
      @extend %paragraph-subtitle;
    }
  }
}
