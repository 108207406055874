@use '~scss/settings' as *;

.form-field-label {
  @extend %paragraph-subtitle-small;
  @include flex(row, flex-start, center);
  @include gap(8px);

  font-family: 'Open Sans';

  svg {
    margin-left: 4px;
  }
  > span {
    color: $color-text-light-grey;
    margin-left: 4px;
  }
}
