@use '~scss/settings' as *;

html,
body {
  @include size(100%);

  scroll-behavior: smooth;
  overscroll-behavior-y: none;

  @supports (-webkit-touch-callout: none) {
    height: calc(100vh - env(safe-area-inset-bottom));
  }
}

#root {
  @include flex(column);
  position: relative;
  height: 100%;
  overscroll-behavior-y: none;
  width: 100%;
  white-space: pre-line;
}

.s-collapsible-anim {
  &.transitioning {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

:root {
  @extend %ncoded-button;
  @extend %ncoded-checkbox;
  @extend %radio-button;
  @extend %ncoded-range;
}

@include ncoded-input;
@include ncoded-select;
@include ncoded-modal;
@include ncoded-textarea;

body {
  --border-radius: #{$border-radius};
  --font-family: 'Roboto';
  --color-primary: #{$color-primary-dark-blue};
  --line-height: 18.75px;

  @extend %font;

  margin: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend %opensans;

    margin: 0;
  }

  h1 {
    @extend %heading1;
  }

  h2 {
    @extend %heading2;
  }

  h3 {
    @extend %heading3;
  }

  h4 {
    @extend %heading4;
  }

  p {
    @extend %paragraph;

    &.descriptive {
      @extend %paragraph-descriptive;
    }

    &.subtitle {
      @extend %paragraph-subtitle;

      &--small {
        @extend %paragraph-subtitle-small;
      }
    }
  }

  .ncoded-toggle--checked {
    background-color: $color-primary-dark-blue;
  }

  input {
    @extend %input;

    &.descriptive {
      @extend %input-descriptive;
    }
  }

  a {
    @extend %paragraph-descriptive;

    color: $color-anchor;
    text-decoration: none;
  }
  ol {
    margin-block-start: 0;
    padding-inline-start: 16px;
  }
  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    list-style-type: none;
    li {
      margin-bottom: 24px;
    }
    &.checkbox-group {
      @include flex(column);

      gap: 24px;
      width: 100%;

      .ncoded-checkbox {
        > span {
          align-self: center;
        }
      }
    }
  }

  button.svg-button-wrapper {
    @include flex(row, center, center);

    gap: 8px;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    color: inherit;

    &:hover {
      cursor: pointer;
    }
  }

  .tooltip-information {
    @extend %paragraph-descriptive;

    padding: 32px;
    width: 100%;
    background-color: $color-grey-fc;
    border: 1px solid $color-grey-d6;
    border-radius: $border-radius-5;
    position: relative;
    max-width: unset;

    &::before {
      @extend %arrow-bottom;

      border-color: $color-grey-d6;
    }

    > .tippy-content {
      white-space: pre-wrap;
      width: 100%;
    }
  }

  .iframe-video-wrapper {
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    aspect-ratio: 2.5;

    iframe {
      @include size(100%);

      border: none;
    }
  }

  .view-guard-msg {
    color: $color-primary-dark-blue;
    font-style: italic;
  }
}
.ncoded-button {
  &--link,
  &--solid,
  &--outline {
    height: 51px;
  }
}

table {
  padding: 0;
  border-spacing: 0;
  table-layout: fixed;

  thead {
    tr {
      th {
        border-bottom: 1px solid $color-primary-light-teal;
        @extend %paragraph-subtitle-small;
        color: $color-secondary-light-grey;
        white-space: nowrap;
        width: fit-content;
        text-align: start;
        padding: 16px;
        font-family: 'Open sans';

        &:last-of-type {
          text-align: end;
        }
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: $color-gray-f0;
      }

      td {
        @extend %paragraph;

        max-width: 400px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        padding: 16px;
        &:last-of-type {
          text-align: end;
        }
      }
    }
  }
}
