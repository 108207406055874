@use '~scss/settings' as *;

.payment-settings {
  @include flex(row);

  gap: 32px;

  > * {
    flex: 1;
  }

  &__additional-info {
    @include flex(column);

    gap: 32px;
  }

  &__not-subscribed {
    @include flex(row, flex-start, center);

    gap: 8px;
    border: 1px solid $color-error;
    color: $color-error;
    padding: 20px;
    align-self: flex-start;

    svg {
      fill: $color-error;
    }
  }

  .searchality-review-card__heading {
    h5 {
      @extend %paragraph-subtitle;

      color: $color-secondary-light-grey-2;
    }
  }

  &-wrapper {
    @include flex(column);

    gap: 32px;

    &__actions {
      @include flex(column);

      gap: 32px;

      form {
        @include flex;

        max-height: 34px;

        gap: 32px;

        button.ncoded-button {
          max-height: 32px;
          min-height: unset !important;
          padding: 4px 16px;
        }
      }
    }
  }
}
