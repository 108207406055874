@use '~scss/color' as *;
@use '~scss/tools/tools' as *;

@mixin ncoded-modal {
  .ncoded-modal {
    .overlay__content {
      background: rgba(33, 33, 33, 0.41);
    }

    &__content {
      @include flex(column);

      max-height: 90%;
      border-radius: 10px;
      filter: none;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04),
        0px 4px 4px rgba(235, 235, 235, 0.08),
        0px 4px 14px rgba(231, 231, 231, 0.05), 0px 4px 5px rgba(0, 0, 0, 0.05);
      min-height: unset;

      header.with-title {
        @extend %paragraph-subtitle;

        padding: 12px 24px;
        color: $color-black;
        white-space: pre-wrap;

        button.ncoded-modal__close {
          @include size(20px);

          svg {
            @include size(100%);

            stroke-width: 1.8px;
            color: $color-text-light-grey;
          }
        }
      }

      main {
        @include flex(column, flex-start, flex-start);

        padding: 24px 24px 32px;
        flex: 1;
        overflow: auto;

        > * {
          white-space: pre-wrap;
        }
      }

      footer {
        .ncoded-button:not(:first-of-type) {
          margin-left: 16px;
        }
      }
    }
  }
}
