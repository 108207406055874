@use '~scss/settings' as *;

.checkbox-with-input-field {
  @include flex(row, space-between, center);

  width: 100%;

  > * {
    flex: 1;
  }

  .ncoded-checkbox__label {
    @extend %input-descriptive;
  }

  .ncoded-checkbox {
    .ncoded-checkbox__box {
      & + * {
        margin-left: 50px;
      }
    }
  }
}
