@use '~scss/tools/tools' as *;

%heading1 {
  @include font(64px, 87px, 700);
}

%heading2 {
  @include font(40px, 54px, 800);
}

%heading3 {
  @include font(32px, 44px, 700);
}

%heading4 {
  @include font(16px, 22px, 700);
}

%paragraph {
  @include font(16px, 26px, 400);

  margin: 0;
}

%paragraph-descriptive {
  @include font(12px, 16px, 300);
}

%paragraph-subtitle {
  @include font(20px, 27px, 700);

  font-family: 'Open Sans';
}

%paragraph-subtitle-small {
  @include font(13px, 18px, 700);
}

%input {
  @include font(16px, 22px, 400);
}

%input-descriptive {
  @include font(14px, 18px, 400);
}
