@use '~scss/settings' as *;

.schools-of-group {
  @include flex(column, flex-start, center);

  flex: 1;
  gap: 32px;

  &__list {
    @include flex(column);

    width: 100%;
    gap: 20px;
  }

  &__description {
    @extend %paragraph-subtitle;

    align-self: flex-start;
    color: $color-secondary-light-grey-2;
  }
}
