@use '~scss/settings' as *;

.searchality-review-card {
  @include flex(column);

  gap: 16px;

  &__heading {
    @include flex(row, space-between, center);

    min-height: 24px;

    h5 {
      @extend %paragraph-subtitle-small;
    }

    button.svg-button-wrapper {
      color: $color-primary-light-teal;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.orange > &__content {
    border-color: $color-primary-orange;
  }

  &__content {
    @include flex(column);

    @extend %card-container;

    gap: 24px;
    padding: 16px;
  }
}
