@use '~scss/settings' as *;

.widget-settings {
  @include gap(32px, column);

  section {
    @include flex(column);
    @include card;

    gap: 16px;

    h1 {
      @extend %heading3;
    }
  }

  &__widget-status {
    div {
      @include flex(row);

      min-height: 36px;

      > * {
        flex: 1;
        margin: auto;
      }

      button.ncoded-button,
      .empty {
        margin: 0;
        max-width: max-content;
        min-width: 100px;
      }

      span {
        &.orange {
          color: $color-primary-orange;
        }

        &.blue {
          color: $color-primary-dark-blue;
        }
      }
    }
  }
}
