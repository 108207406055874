@use '~scss/settings' as *;

.seachality-profile-actions {
  @include flex(row, flex-end);

  gap: 12px;
  position: absolute;
  right: 0;
  top: 0;
}
