@use '~scss/color' as *;
@use '~scss/tools/tools' as *;
@use '~scss/variables' as *;
@use '~scss/general/general' as *;

%radio-button {
  .radio-button {
    @include gap(8px, row);

    justify-content: space-between;
    padding: 16px 10px 16px 12px;
    border: 1px solid $color-primary-light-teal;
    border-radius: $border-radius-6;
    background-color: $color-white;

    input {
      @include size(15px);

      position: static;
      opacity: 1;
      margin: 0;
      appearance: none;
      background-color: #f3f4f6;
      border-radius: 50%;
      border: 1px solid $color-grey-b7;
      background-color: transparent;

      &:checked {
        border: solid 5px $color-grey-e6;
        background-color: $color-primary-dark-blue;
      }
    }

    &__label {
      @include gap(4px, column);

      display: flex;
      flex-direction: column;
      white-space: pre;

      .title {
        color: $color-primary-dark-blue;
      }

      .description {
        @extend %input-descriptive;

        color: $color-grey-af;
      }
    }

    &--checked {
      background-color: $color-primary-dark-blue;
      color: $color-white;
      border-color: $color-primary-dark-blue;

      .radio-button {
        &__label {
          .title {
            color: $color-white;
          }

          .description {
            color: $color-primary-light-teal;
          }
        }
      }
    }
  }
}
