@use '~scss/settings' as *;

.searchality-admin-schools {
  @extend %page-content-container;

  overflow: hidden;

  &--preview {
    width: 100%;
    max-width: 1400px;
  }

  td.school-name {
    max-width: 300px;
  }

  .searchality-pagination-info {
    margin: 36px 0 48px;
  }

  &--active-column {
    color: $color-primary-teal;

    &--inactive {
      color: $color-primary-orange;
    }
  }
}
