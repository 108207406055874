@use '~scss/settings' as *;

.searchality-profile-metadata {
  @include flex(column);

  gap: 20px;
  padding: 20px;
  border-radius: 20px;
  background: $color-white;
  border: 1px solid $color-primary-light-teal;
  color: $color-primary-dark-blue;

  div {
    @include flex(column);

    gap: 4px;
  }

  .title {
    @include font(16px, 18px, 400);

    color: $color-secondary-light-grey;
  }

  .description {
    @extend %paragraph-subtitle;
  }

  .status {
    @extend %paragraph-subtitle-small;

    width: max-content;
    padding: 10px;
    color: $color-primary-dark-blue;
    border: 1px solid currentColor;
    border-radius: 5px;
  }
}
