@use '~scss/settings' as *;

.searchality-heading {
  @include flex(row, space-between, flex-start);

  margin-bottom: 48px;

  width: 100%;

  h1 {
    @extend %heading3;
    overflow-wrap: break-word;
  }
  &__left {
    max-width: 60%;
  }
  .heading-buttons-group {
    @include flex(row, center, center);
    @include gap(8px);

    button {
      max-height: 40px;
    }
    > .ncoded-button {
      @extend %paragraph-subtitle-small;
      &--outline {
        color: $color-primary-dark-blue;
      }
    }
    .vacancy-status {
      @extend %paragraph-descriptive;
      color: $color-text-light-grey;
      > span {
        color: $color-primary-teal;
        @extend %paragraph-subtitle-small;
      }

      &--inactive {
        > span {
          color: $color-primary-orange;
        }
      }
    }
  }
}
