@use '~scss/general/general' as *;

.searchality-admins-page {
  @extend %page-content-container;

  .dropdown {
    width: 250px;
    margin-bottom: 8px;
  }
}
