@use '~scss/settings' as *;

.candidate-profile {
  @include flex(row, center);
  @include gap(40px);

  padding: 80px 0px 32px;
  margin: 0 auto;
  width: 100%;

  &__left {
    @include flex(column);
    @include gap(20px, column);

    min-width: 400px;
    max-width: 400px;
  }

  &__right {
    position: relative;
    max-width: 900px;
    flex: 1;

    small {
      width: 100%;
      white-space: pre-wrap;
      text-align: right;
      display: block;
    }
  }

  button.go-back-button {
    justify-content: flex-start;
  }

  .searchality-profile {
    margin-top: 70px;
  }
}
