@use '~scss/color' as *;
@use '~scss/tools/tools' as *;
@use '~scss/variables' as *;

%input-padding {
  padding: 36px 0 12px 16px;
}

@mixin base-input {
  --required-color: #{$color-required-asterisk};

  background-color: $color-grey-fc;
  border-radius: $border-radius-5;
  border: 1px solid $color-grey-d6;

  label {
    color: $color-grey-af;
  }
}

@mixin form-control($include-error: true) {
  &__hint,
  &__error {
    @extend %paragraph-descriptive;

    margin: 10px 0 4px 4px;
  }

  &__error {
    color: $color-error;
  }

  @if ($include-error == true) {
    &--error {
      color: $color-error;
      border-color: $color-error;

      &:focus-within {
        border-color: $color-error;
      }

      label {
        color: inherit;
      }
    }
  }
}

%main {
  margin: auto;
  min-width: 920px;
  width: 90%;
  max-width: 1240px;
  border: 1px solid transparent;
  border-radius: $border-radius;
  background-color: $color-white;

  &.smaller {
    width: 640px;
    padding: 64px;
  }

  form > [class*='wrapper'],
  form > .fields-row {
    & + [class*='wrapper'],
    & + .fields-row {
      margin-top: 25px;
    }
  }
}
